<template>
    <div class="">
        <mdb-row>
            <mdb-col class="mt-2" md="4" xl="3">
                <mdb-card>
                    <!--Card Data-->
                    <mdb-row class="mt-3">
                        <mdb-col md="5" col="5" class=" text-left pl-4">
                            <mdb-btn tag="a" floating size="lg" color="primary" @click="$router.push('/product')" class="ml-4">
                                <i class="fas fa-lightbulb"></i>
                            </mdb-btn>
                        </mdb-col>

                        <mdb-col md="7" col="7" class=" text-right pr-5">
                            <h5 class="ml-4 mt-4 mb-2 font-weight-bold">{{ $numeral(product_number).format('0,0') }}</h5>
                            <p class="font-small grey-text">商品</p>
                        </mdb-col>
                    </mdb-row>
                    <!--/.Card Data-->
                </mdb-card>
                <!--/.Card-->
            </mdb-col>
        </mdb-row>
    </div>
</template>

<script>
    import { mdbRow, mdbCol, mdbCard, mdbBtn } from 'mdbvue';
    export default {
        components: {
            mdbRow,
            mdbCol,
            mdbCard,
            mdbBtn,
        },
        data() {
            return {
                product_number: 0,
                contact_number: 0,
            };
        },
        mounted() {
            console.clear();
            this.get_data();
        },
        watch: {},
        computed: {},
        methods: {
            // 取出資料
            get_data() {
                let vue = this,
                    query = [];
                query = [
                    {
                        name: 'product',
                        num: '',
                        data: {
                            active: {
                                type: 0,
                                value: '1',
                            },
                        },
                    },
                ];
                vue.$store
                    .dispatch('get_form', {
                        payload: {
                            url: `data/get_sql_data/?query=${JSON.stringify(query)}`,
                        },
                    })
                    .then((res) => {
                        vue.product_number = res.data.productNumber;
                    });
            },
        },
    };
</script>
